import * as Sentry from '@sentry/core';
import { Scope } from '@sentry/types';

const loggers = {};

export enum LEVELS {
  DEBUG,
  INFO,
  WARN,
  ERROR,
}

export class Logger {
  private id: string;

  constructor(id: string) {
    const cached = loggers[id];
    if (cached) {
      return cached;
    }

    loggers[id] = this;
    this.id = id;
  }

  /**
   * Logs a debug message.
   *
   * @param args The data to log.
   */
  public debug(...args: any[]): void {
    /* stub */
  }

  /**
   * Logs info.
   *
   * @param args The data to log.
   */
  public info(...args: any[]): void {
    /* stub */
  }

  /**
   * Logs a warning.
   *
   * @param args The data to log.
   */
  public warn(...args: any[]): void {
    /* stub */
  }

  /**
   * Logs an error.
   *
   * @param args The data to log.
   */
  public error(...args: any[]): void {
    /* stub */
  }
}

export function connectConsole() {
  const proto = Logger.prototype;
  // NodeJS has no console.debug
  proto.debug = console.log.bind(console); // tslint:disable-line
  proto.info = console.info.bind(console); // tslint:disable-line
  proto.warn = console.warn.bind(console); // tslint:disable-line
  proto.error = console.error.bind(console); // tslint:disable-line
}

export function connectSentry(sentry /*: typeof Sentry*/, level: LEVELS = LEVELS.WARN) {
  const proto = Logger.prototype;

  const restToExtra = (rest: any[]) => {
    if (!rest.length) {
      return {};
    }

    const extra = {};
    if (typeof rest[0] === 'object') {
      Object.assign(extra, rest[0]);
    }
    return extra;
  };

  const safeId = (id) => id.replace(/\//g, '__');

  const addExtra = (scope: Scope, id: string, rest: any) => {
    scope.setTag('logger', safeId(id));
    const extra = restToExtra(rest);
    Object.keys(extra).forEach((key) => {
      if (key === 'fingerprint') {
        scope.setFingerprint(extra[key]);
      } else {
        scope.setExtra(key, extra[key]);
      }
    });
  };

  proto.debug = function (message, ...rest) {
    // tslint:disable-line
    (console.debug || console.log)(message, ...rest); // tslint:disable-line
    if (level <= LEVELS.DEBUG) {
      sentry.withScope((scope: Scope) => {
        scope.setLevel('debug');
        addExtra(scope, this.id, rest);
        sentry.captureMessage(message);
      });
    }
  };
  proto.info = function (message, ...rest) {
    // tslint:disable-line
    console.info(message, ...rest); // tslint:disable-line
    if (level <= LEVELS.INFO) {
      sentry.withScope((scope: Scope) => {
        scope.setLevel('info');
        addExtra(scope, this.id, rest);
        sentry.captureMessage(message);
      });
    }
  };
  proto.warn = function (message, ...rest) {
    // tslint:disable-line
    console.warn(message, ...rest); // tslint:disable-line
    if (level <= LEVELS.WARN) {
      sentry.withScope((scope: Scope) => {
        scope.setLevel('warning');
        addExtra(scope, this.id, rest);
        sentry.captureMessage(message);
      });
    }
  };
  proto.error = function (messageOrError, ...rest) {
    // tslint:disable-line
    console.error(messageOrError, ...rest); // tslint:disable-line
    if (level <= LEVELS.ERROR) {
      sentry.withScope((scope: Scope) => {
        scope.setLevel('error');
        addExtra(scope, this.id, rest);
        sentry.captureException(messageOrError);
      });
    }
  };
}

if (ENV.ENVIRONMENT === 'development') {
  connectConsole();
} else if (ENV.ENVIRONMENT === 'test') {
  // Ignore all prints
  connectConsole();
} else {
  connectSentry(Sentry, LEVELS.WARN);
}

export function getLogger(id: string): Logger {
  return loggers[id] || new Logger(id);
}
